
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useRouterUtils } from "@/utils/mixins";

import CSpinner from "@/components/spinner.vue";
import CFormInputText from "@/components/form/input-text.vue";
import CPatientSearch from "@/views/patient/search.vue";

import { Patient } from "@/store/patient/types";

import { addMoneyMask } from "@/utils/formatters";

const VProcedure = defineComponent({
  name: "VProcedure",
  components: { CSpinner, CFormInputText, CPatientSearch },
  setup() {
    useHead({ title: "Agendar procedimentos | obmed" });
    const store = useStore();
    const route = useRoute();
    const { normalizeRouteParam } = useRouterUtils();

    const procedures = computed(() => store.state.procedure.list);
    const steps = ref(["Paciente", "Procedimento", "Estabelecimento"]);
    const currentStep = computed(() =>
      route.name?.toString() == "procedure" && patient.value?.id
        ? steps.value?.findIndex((step) => step === "Procedimento")
        : route.name?.toString().includes("-establishment")
        ? steps.value?.findIndex((step) => step === "Estabelecimento")
        : !patient.value?.id
        ? steps.value?.findIndex((step) => step === "Paciente")
        : (steps.value?.length || 0) - 1
    );

    const search = ref("");
    const loading = reactive({ list: false });
    const patient = ref<Patient | null>(null);

    async function searchProcedure() {
      loading.list = true;
      await store.dispatch("searchProcedure", { search: search.value });
      loading.list = false;
    }

    searchProcedure();
    watch(search, searchProcedure);

    return { procedures, search, steps, loading, currentStep, addMoneyMask, patient, normalizeRouteParam };
  },
});

export default VProcedure;
